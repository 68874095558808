<template>
    <div class="guarantee-look">
        <backHeader C_Title='correctOrder'></backHeader>
        <div class="guarantee-look-list">
            <div>
                <h3>批改单查看</h3>

                <div>
                    <el-button type="warning" :disabled="check_status!=3" @click="goPay()">去支付</el-button>
                    <a :href="baseUrl+change_bill_url" target="_blank" style="display: inline-block;margin: 0 20px;" v-if="check_status!=3||check_status!=0" ><el-button type="danger">发票下载</el-button></a>
                    <a :href="baseUrl+electronic_file" target="_blank" v-if="check_status!=3||check_status!=0" ><el-button type="primary">电子批单下载</el-button></a>
                </div>
            </div>
            <div class="guarantee-look-list-2">
                <div>
                    <img src="../../image/guaranteeLook/1.png" alt="" v-if="check_status==1">
                    <img src="../../image/guaranteeLook/0.png" alt="" v-if="check_status==0">
                    <img src="../../image/guaranteeLook/3.png" alt="" v-if="check_status==3">
                </div>
                <div>
                    <div>保单号：{{policy_num}}</div>
                    <div>
                        <div>流水号：{{num_id}}</div>
                        <div>创建时间：{{c_time}}</div>
                        <div> 保障期限：{{s_time}} 至 {{e_time}}</div>
                    </div>
                    <div>保费 <span>¥{{policy_money}}</span></div>
                </div>
                <div>
                    <div>
                        <div>产品名称：{{product_name}}</div>
                        <div>身故残疾：{{Safeguard[0]}}</div>
                    </div>
                    <div>
                        <div>保险公司：{{company_name}}</div>
                        <div>意外医疗：{{Safeguard[1]}}</div>
                    </div>
                    <div>
                        <div>职位类别：{{job_name}}</div>
                        <div>误工费：{{Safeguard[2]}}</div>
                    </div>
                    <div>
                        <div>工种：{{work_name}}</div>
                    </div>
                    <div>
                        <div>扩展方案：附加24小时、十级伤残升级100%</div>
                    </div>
                </div>
            </div>
            <div class="guarantee-look-list-3">
                <div>
                    <div>
                        批单信息
                    </div>
                    <div>
                        <div>
                            批单号: {{check_num}}
                        </div>
                        <div>
                            起保时间: {{check_s_time}}
                        </div>
                        <div>
                            批改人员清单: {{insure_file}}
                        </div>
                        <div>
                            <div>
                                批增人数: {{check_inc}}人
                            </div>
                            <div>
                                变更申请单: {{insure_file}}
                            </div>
                        </div>
                        <div>
                            <div>
                                替换人数: {{check_dec}}人
                            </div>
                            <div>
                                投保人: {{applicant_name}}
                            </div>
                        </div>
                        <div>
                            应缴保费: ￥{{money}}
                        </div>
                        <div>
                            被保人: {{assured_name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="guarantee-look-list-4">
                <div>
                    <div>增加人员</div>
                    <el-table
                            align="center"
                            :data="addData"
                            border
                            max-height="500px"
                            style="width: 100%">
                        <el-table-column
                                align="center"
                                type="index"
                                width="80px"
                                label="序号">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="name"
                                label="姓名">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="paper_num"
                                label="身份证号">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="job_name"
                                label="工作种类">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="money"
                                label="保费金额">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                label="保费期限">
                                <template slot-scope="scope">
                                        <div>
                                            <span>{{scope.row.s_time}} 至 {{scope.row.e_time}}</span>
                                        </div>
                                    </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        <!-- <el-pagination
                                @size-change="handleSizeChange(1)"
                                @current-change="handleCurrentChange(1)"
                                :current-page.sync="currentPage1"
                                :page-size="100"
                                layout="total, prev, pager, next"
                                :total="1000">
                        </el-pagination> -->
                    </div>
                </div>
            </div>
            <div class="guarantee-look-list-5">
                <div>
                    <div>替换人员</div>
                    <el-table
                            max-height="500px"
                            align="center"
                            :data="desData"
                            border
                            style="width: 100%">
                        <el-table-column
                                align="center"
                                type="index"
                                width="80px"
                                label="序号">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="name"
                                label="姓名">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="paper_num"
                                label="身份证号">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="job_name"
                                label="工作种类">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="money"
                                label="保费金额">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="time"
                                label="保费期限">
                                <template slot-scope="scope">
                                        <div>
                                            <span>{{scope.row.s_time}} 至 {{scope.row.e_time}}</span>
                                        </div>
                                </template>
                        </el-table-column>
                    </el-table>
                    <!-- <el-pagination
                            @size-change="handleSizeChange(2)"
                            @current-change="handleCurrentChange(2)"
                            :current-page.sync="currentPage2"
                            :page-size="100"
                            layout="total, prev, pager, next"
                            :total="1000">
                    </el-pagination> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import backHeader from "../../components/back/backHeader";

    export default {
        name: "correctLook",
        components: {
            backHeader
        },
        data() {
            return {
                num_id:"",//流水号
                check_status:'',//保单状态
                change_id:"",//批单号
                baseUrl:"",
                product_name:"",//产品名
                policy_num:"",//保单号
                Safeguard:"",//保障
                c_time:"",//创建时间
                s_time:"",//开始时间
                e_time:"",//终止时间
                policy_money:"",//保费
                company_name:'',//保险公司
                job_name:"",// 职位类别
                work_name:"",//工种
                check_s_time:"",//起保时间
                electronic_file:'',//电子批单
                change_bill_url:'',//电子发票
                check_num:'',//批单号
                applicant_name:'',//投保人
                assured_name:"",//被保人
                insure_file:"",//批改人员清单
                addData: [],//添加人员
                desData:[], //替换
                check_inc:'',//批增人数
                check_dec:"",//批减人数
                money:"",//批改费用
            }
        },
        created() {
            this.baseUrl=this.$store.state.baseUrl
            this.num_id = this.$route.query.num_id
            this.check_status = this.$route.query.check_status
            this.change_id = this.$route.query.change_id
        },
        mounted() {
            this.getCheckDetail()
        },
        methods: {
            //订单数据
            getCheckDetail:function(){
                console.log(this.change_id)
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: that.$store.state.baseUrl + '/api/product/order/checkDetail',
                    method: 'POST',
                    params:{
                        num_id:that.num_id,
                        change_id:that.change_id,
                        // num_id:2004136758863,
                        // change_id:7569,
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        that.product_name=res.data.data.pro_name,//产品名
                        that.policy_num=res.data.data.policy_num,//保单号
                        that.Safeguard=res.data.data.Safeguard,//保障
                        that.c_time=res.data.data.c_time,//创建时间
                        that.s_time=res.data.data.s_time,//开始时间
                        that.e_time=res.data.data.e_time,//终止时间
                        that.policy_money=res.data.data.policy_money,//保费
                        that.company_name=res.data.data.company_name,//保险公司
                        that.job_name=res.data.data.job_name,// 职位类别
                        that.work_name=res.data.data.work_name//工种
                        that.electronic_file=res.data.data.electronic_file//电子批单：
                        that.change_bill_url=res.data.data.change_bill_url//电子发票：
                        that.check_num=res.data.data.check_num
                        that.check_s_time=res.data.data.check_s_time
                        that.applicant_name=res.data.data.applicant_name
                        that.assured_name=res.data.data.assured_name
                        that.addData=res.data.data.addData,//添加人员
                        that.desData=res.data.data.desData//替换
                        that.insure_file=res.data.data.insure_file
                        that.check_inc=res.data.data.check_inc
                        that.check_dec=res.data.data.check_dec
                        that.money=res.data.data.money
                    }else{
                        that.$message.error("加载失败请稍后重试");
                    }              
                }).catch(function (error) {
                    console.log(error);
                });   
            },



            // 去支付
            goPay:function(){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: that.$store.state.baseUrl + '/api/order/orders/getOrderPayUrl',
                    method: 'POST',
                    params:{
                        authCheck:0,
                        source:4,
                        login_type:that.$store.state.login_type,
                        token:that.$store.state.logininformation.token,
                        change_id:that.change_id,
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        window.open(res.data.data.pay_url);
                    }else{
                        that.$message.error(res.data.msg);
                    }             
                }).catch(function (error) {
                    console.log(error);
                });                    
            },




            handleSizeChange(type) {
                // eslint-disable-next-line no-empty
                if (type == 1) {

                    // eslint-disable-next-line no-empty
                } else {

                }
            },
            handleCurrentChange(type) {
                // eslint-disable-next-line no-empty
                if (type == 1) {

                    // eslint-disable-next-line no-empty
                } else {

                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .guarantee-look {
        // position: fixed;
        // top: 0;
        // bottom: 0;
        width: 100%;
        background: #F4F3F6;
        overflow-y: scroll;
        padding-top:70px;
        padding-bottom: 40px;
    }

    .guarantee-look-list {
        width: 70%;
        margin-left: 15%;

        & > div:nth-child(1) {
            display: flex;
            width: 100%;
            justify-content: space-between;
            background: #F3F5FB;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 20px;

            & > h3 {
                padding: 0;
                margin: 0;
            }

            & > div:nth-child(2) {
                display: flex;
                justify-content: flex-end;
            }
        }

        .guarantee-look-list-2 {
            display: flex;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 20px;
            background: white;

            & > div:nth-child(1) {
                width: 10%;
            }

            & > div:nth-child(2) {
                & > div {
                    width: 100%;
                    text-align: left;
                }

                & > div:nth-child(1) {
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 54px;
                }

                & > div:nth-child(2) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(101, 101, 101, 1);
                    line-height: 30px;
                }

                & > div:nth-child(3) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(101, 101, 101, 1);
                    line-height: 30px;

                    & > span {
                        color: #E93D39;
                        font-size: 24px;
                    }
                }

                width: 50%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            & > div:nth-child(3) {
                margin-top: 40px;
                width: 50%;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(101, 101, 101, 1);
                line-height: 30px;

                & > div {
                    display: flex;
                    justify-content: space-between;

                    & > div:nth-child(2) {
                        width: 40%;
                        text-align: left;
                    }
                }
            }
        }

        .guarantee-look-list-3 {
            display: flex;
            justify-content: flex-start;
            background: white;

            & > div {
                width: 100%;
                border-top: 1px solid rgba(243, 243, 245, 1);
                margin: 40px 20px 40px 20px;
                padding-left: 10%;
                text-align: left;

                & > div:nth-child(1) {
                    margin-top: 15px;
                }

                & > div:nth-child(2) {
                    margin-top: 15px;
                    width: 50%;

                    & > div {
                        display: flex;
                        justify-content: space-between;

                        & > div {
                            width: 50%;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .guarantee-look-list-4, .guarantee-look-list-5 {
            display: flex;
            justify-content: flex-start;
            background: white;

            & > div {
                width: 100%;
                border-top: 1px solid rgba(243, 243, 245, 1);
                margin: 40px 20px 40px 20px;
                padding-left: 10%;
                padding-right: 10%;
                text-align: left;
                box-sizing: border-box;

                & > div:nth-child(1) {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    color: rgba(16, 16, 16, 1);
                    font-size: 14px;
                }
                & > div:nth-child(3) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }


    }
</style>